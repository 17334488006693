import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../../components/layout"
import CTABlock from "../../components/blocks/cta_block"
import GridFilters from "../../components/gridcourses"

const CompetitionsCampsPage = ({ data }) => {
  if (!data?.wagtail?.page) {
    return <></>
  }
  const page = data.wagtail.page
  let ancestor = { children: [], button: {} }
  data.wagtail.page.ancestors.forEach(item => {
    if ("children" in item) {
      ancestor = item
    }
  })
  const pageCompliance = {
    __typename: "SimplePage",
    title: "Compliance",
    slug: "compliance",
    live: true,
  }
  const pageCourses = {
    __typename: "CoursesPage",
    title: "Courses",
    slug: "courses",
    live: true,
  }

  if (
    ancestor.children.filter(item => item.title === "Compliance").length === 0
  ) {
    ancestor.children.splice(2, 0, pageCompliance)
  }

  if (
    ancestor.children.filter(item => item.title === "Courses" && item.live)
      .length === 0
  ) {
    ancestor.children.splice(2, 0, pageCourses)
  }

  return (
    <>
      <Layout
        isContent={true}
        subMenu={ancestor.children}
        button={data.wagtail.page.button}
        parent={"coaches"}
        siteTitle={page.title}
      >
        <section className="breadcrumb-area breadcrumb-sub-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb nav-breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/coaches">Coaches</Link>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>
        <section className="about-area main-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h1 className="hero-heading">{page.title}</h1>
                <p>{page.descriptionSimple}</p>
                {page.feedImage && (
                  <div className="clib-area">
                    <div className="club-img full-width-image">
                      <img src={page.feedImage.src} alt="swim" />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div
                  className="c-title-content"
                  dangerouslySetInnerHTML={{
                    __html: page.bodySafe,
                  }}
                />
              </div>
            </div>
          </div>
        </section>
        <GridFilters data={page} />
        {data.wagtail.page.fullContent &&
          data.wagtail.page.fullContent.length > 0 && (
            <CTABlock data={data.wagtail.page.fullContent[0]}></CTABlock>
          )}
      </Layout>
    </>
  )
}
export const query = graphql`
  {
    wagtail {
      page(slug: "courses") {
        ... on CoursesPage {
          descriptionSimple
          id
          bodySafe
          live
          feedImage {
            src
            height
            width
          }
          slug
          children {
            slug
            ... on CourseEvent {
              id
              descriptionSimple
              bodySafe
              name
              contactName
              contactEmail
              firstPublishedAt
              dates {
                ... on CourseDateTimeBlock {
                  date
                  startTime
                  endTime
                }
              }
              locationName
              locationAddress
              totalHours
              minAgeOfRegistrants
              maxNumberOfRegistrants
              fee
              descriptionSimple
              learningFacilitators {
                ... on CourseFacilitatorBlock {
                  lastName
                  firstName
                }
              }
              live
              isProtected
              ancestors(order: "-depth", limit: 1) {
                title
                depth
              }
            }
          }
          ancestors(order: "-depth") {
            ... on CoachesPage {
              children {
                title
                slug
                ... on SimplePage {
                  live
                  isProtected
                }
                __typename
              }
              button {
                text
                name
                url
              }
            }
          }

          title
          fullContent {
            ... on CTABlock {
              id
              title
              image {
                ... on ImageObjectType {
                  src
                  file
                  width
                  height
                }
              }
              description
              orientation
              url
              urlName
            }
          }
        }
      }
    }
  }
`
export default CompetitionsCampsPage
