import React from "react"
import { Link } from "gatsby"
import Moment from "moment"
import Helmet from "react-helmet"
import Loader from "react-loader-spinner"

class GridFilters extends React.Component {
  constructor(props) {
    super(props)
    const onlyLive = props.data.children
      .filter((item) => item.live)
      .filter(
        (item) => Date.parse(item.dates[item.dates.length - 1].date) >= Date.now()
      )

    const orderData = onlyLive.sort(
      (a, b) => Date.parse(a.dates[0].date) - Date.parse(b.dates[0].date)
    )

    this.state = {
      data: orderData,
      initialData: orderData,
      page: props.data,
      text: undefined,
      loadMore: 20,
      filter_event: "upcoming",
      filter_type: undefined,
      filter_gender: "",
      loading: false,
    }
  }

  onClick = (event) => {
    const min = 0.5
    const max = 1.8
    const rand = Math.floor(Math.random() * (max - min + 0.7) + min)
    this.setState(
      { loading: true },

      () => {
        setTimeout(
          function () {
            this.setState({
              loading: false,
              loadMore: this.state.loadMore + 20,
            })
          }.bind(this),
          rand * 1000
        )
      }
    )
  }

  onReset = (event) => {
    this.setState({ loadMore: 5 })
  }

  getloadMore = () => {
    const total = this.state.data ? this.state.data.length : 0
    if (total < 20) {
      return null
    }
    if (this.state.loadMore >= total) {
      return <></>
    }
    return (
      <button onClick={this.onClick} className="button-load-more">
        {" "}
        Load More{" "}
      </button>
    )
  }

  async componentDidMount() {
    Moment.locale("en")
  }

  renderDates = (infoDate) => {
    console.log(infoDate)
    let firstDate = ""
    let lastDate = ""
    if (infoDate && infoDate.length > 0) {
      firstDate = infoDate[0].date
      lastDate = infoDate[infoDate.length - 1].date
      return `${Moment(firstDate).format("MMMM DD, YYYY")} - ${Moment(
        lastDate
      ).format("MMMM DD, YYYY")}`
    }
    return ""
  }

  render() {
    return (
      <>
        <section className="events-grid">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="table-area">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Date(s)</th>
                        <th scope="col">Title</th>
                        <th scope="col">Location</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.data.map((item, index) => {
                        if (index <= this.state.loadMore) {
                          return (
                            <tr key={`${item.slug}${index}`}>
                              <th scope="row" style={{ maxWidth: "200px" }}>
                                {this.renderDates(item.dates)}
                              </th>
                              <td>
                                <h6>
                                  <Link to={`/coaches/courses/${item.slug}`}>
                                    {item.name || " - "}
                                  </Link>
                                </h6>
                              </td>
                              <td>
                                <a
                                  rel="noopener noreferrer"
                                  target="_blank"
                                  href={`https://www.google.com/maps/search/${item.locationAddress}`}
                                >
                                  {item.locationName}
                                </a>
                              </td>
                            </tr>
                          )
                        }
                        return ""
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 align-items-center text-center">
                {this.state.loading && (
                  <>
                    <Loader
                      type="Puff"
                      color="#00aad8"
                      height={100}
                      width={100}
                    />
                    <br />
                  </>
                )}
                {this.getloadMore()}
              </div>
            </div>
          </div>
        </section>
        <Helmet></Helmet>
      </>
    )
  }
}

export default GridFilters
